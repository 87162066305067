@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/f6e0178014af62b1-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/3c2c42dbb6c81722-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/8d9815c816af8b7e-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/b6a9f8c750a44c7b-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/8491dd65f5b7f62f-s.p.woff) format('woff');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/21d0842dbb1773c1-s.p.woff) format('woff');
font-display: swap;
font-weight: 100;
font-style: italic;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/3f760941a7e7615d-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/d9a76307f7a3aa1f-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/7b8b0b3b01cbaff5-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/c6b8c7b66bdf23ad-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/c3530f166eefc7a4-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/2b5fc62246292b85-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/a94fd49072a38ecc-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/70fe552e394e16dd-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/c76c265f52a83b64-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: 'fontSofiaPro';
src: url(/_next/static/media/4771ba2827fa91d4-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: 'fontSofiaPro Fallback';src: local("Arial");ascent-override: 71.98%;descent-override: 23.99%;line-gap-override: 0.00%;size-adjust: 104.19%
}.__className_14879d {font-family: 'fontSofiaPro', 'fontSofiaPro Fallback'
}.__variable_14879d {--font-sofia-pro: 'fontSofiaPro', 'fontSofiaPro Fallback'
}

